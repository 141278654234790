import wretch from "wretch";

const apiClient = wretch().options({ credentials: "same-origin" });

export function getRequest(url, params) {
  var client = apiClient.url(url);
  if (params) client = client.query(params);
  return client.get();
}

export function storyPageLoadItems(pageNumber, mostPopular) {
  const FIELDS =
    "id,headline,subheadline,metadata,slug,url,hero-image-s3-key,tags,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,authors,sections,story-template,cards";

  return global
    .wretch("/api/v1/stories")
    .query({
      fields: FIELDS,
      limit: 5,
      offset: 5 * pageNumber
    })
    .get()
    .json(response => response.stories.map(story => ({ story, mostPopular: mostPopular })));
}

export const loadNextStories = pageNumber => {
  const FIELDS = "id,slug,first-published-at,last-published-at";
  return global
    .wretch("/api/v1/collections/latest-news")
    .query({
      fields: FIELDS,
      limit: 20
    })
    .get()
    .json(response =>
      response.items.filter(i => i.type === "story").map(({ story }) => ({ slug: story.slug, id: story.id }))
    );
};

export const bottomStickyTopStory = () => {
  return global
    .wretch("/api/v1/collections/main")
    .get()
    .json(r => r.items[0].story)
    .catch(error => console.log("error", error));
};

// Returns the current User
export function getUser() {
  return global
    .wretch()
    .url("/api/auth/v1/users/me")
    .get()
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

// Logout the User
export function logout() {
  return global
    .wretch()
    .url("/api/auth/v1/logout")
    .get()
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

// Update User Information
export function updateUserInformation(data) {
  return global
    .wretch()
    .url("/api/auth/v1/users/profile")
    .post(data)
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

// login user
export function loginUser(email, password) {
  return global
    .wretch()
    .url("/api/auth/v1/login")
    .headers({ "Content-Type": "application/json" })
    .post({
      username: email,
      password: password
    })
    .json(member => member)
    .catch(ex => Promise.reject(ex));
}

// signup the user
export function signUpUser(options) {
  return global
    .wretch()
    .url("/api/auth/v1/signup")
    .headers({ "Content-Type": "application/json" })
    .post(options)
    .json(member => member)
    .catch(ex => Promise.reject(ex));
}

export function forgotPassword(email) {
  return global
    .wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/forgot-password")
    .headers({ "Content-Type": "application/json" })
    .post({ email })
    .json(response => response)
    .catch(ex => Promise.reject(ex));
}

// Reset the password
export function resetPassword(token, password, repeatPassword) {
  return global
    .wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/reset-password-with-token")
    .post({
      token: token,
      "new-password": password,
      "confirm-password": repeatPassword
    })
    .json(response => response)
    .catch(ex => {
      if (ex.message) {
        const res = JSON.parse(ex.message);
        return Promise.reject(res.error);
      }

      return Promise.reject(ex);
    });
}

// Update User Metadata
export function updateUserMetadata(data) {
  return global
    .wretch()
    .options({ credentials: "same-origin" })
    .url("/api/member/metadata")
    .post({ metadata: data })
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

// Generate JWT token from server which is used in access-type
export function generateJwtToken(email, id, name) {
  return global
    .wretch()
    .url("/generate-token")
    .post({ email, id, name })
    .json(result => result.token)
    .catch(ex => Promise.reject(ex));
}

export function sendSupportEmail(data) {
  return global
    .wretch()
    .url("/support-mail")
    .post(data)
    .res(res => res)
    .catch(ex => Promise.reject(ex));
}

export function sendSubscribeUserNPEmail(data) {
  return global
    .wretch()
    .url("/subscribe-user")
    .post(data)
    .res(res => res)
    .catch(ex => Promise.reject(ex));
}

export function sendDigitalSubscribeUserEmail(data) {
  return global
    .wretch()
    .url("/digital-subscribe-user")
    .post(data)
    .res(res => res)
    .catch(ex => Promise.reject(ex));
}

export function sendSubscribeUserToAdminEmail(data) {
  return global
    .wretch()
    .url("/subscription")
    .post(data)
    .res(res => res)
    .catch(ex => Promise.reject(ex));
}

export function getPageCommentsData(accountId, url) {
  const encodedUrl = btoa(url);
  return global
    .wretch()
    .url(`https://www.metype.com/api/v1/accounts/${accountId}/pages/${encodedUrl}`)
    .get()
    .json(res => res)
    .catch(ex => Promise.reject(ex));
}

export const submitNewsLetter = (id, token, body) => {
  return global
    .wretch()
    .url("/newsletter")
    .query({ id, token })
    .post(body)
    .text(res => res);
};

export const sendTransactionalEmail = options => {
  return global
    .wretch()
    .url("/transactional-emails")
    .headers({ "Content-Type": "application/json" })
    .post(options)
    .res(res => res);
};

export const getCountry = () => {
  return global
    .wretch()
    .url("https://ipinfo.io/json?token=06e346377eff84")
    .get()
    .json(res => res)
    .catch(err => console.log(err));
};
