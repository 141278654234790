import React, { useState } from "react";
import PT from "prop-types";
import get from "lodash/get";
import { NavbarSearch } from "../navbar-search";
import { MenuItems } from "./menu-items";
import { AppLogo } from "../app-logo";
import { SocialFollow } from "../../basic/social-follow";
import { Couplet } from "./couplet";
import { connect } from "react-redux";

import { SisterCompanies } from "../sister-companies";
import { HamburgerIcon } from "./hamburger-icon";
import LoginView from "../../rows/login-view";
import PopupModal from "../../atoms/popup-modal";

import "./styles.m.css";

const NavBarBase = props => {
  const [hamburgerOpened, setHamburgerOpened] = useState(false);
  const [isLoginPopupViewOpen, setIsLoginPopupViewOpen] = useState(false);

  const toggleOffcanvasMenu = () => setHamburgerOpened(!hamburgerOpened);

  const onLoginClick = () => {
    hamburgerOpened && setHamburgerOpened(false);
    setIsLoginPopupViewOpen(!isLoginPopupViewOpen);
  };

  // Login Popup Modal View
  const loginPopupView = () => {
    if (!isLoginPopupViewOpen) {
      return null;
    }

    return (
      <PopupModal closePopup={onLoginClick}>
        <LoginView closePopup={onLoginClick} makePayment={false} />
      </PopupModal>
    );
  };

  const styleName = props.publisherAttributes.lang === "ur" ? "urdu-search-row-one" : "search-row-one";
  const langClassName = props.publisherAttributes.lang === "ur" ? "urdu-navbar" : "navbar";
  const BleedingRowMenuStyle = props.publisherAttributes.lang === "ur" ? "ur-bleeding-row--menu" : "bleeding-row--menu";

  return (
    <div styleName={langClassName}>
      <div styleName="bleeding-row">
        <div
          styleName={`container ${
            props.publisherAttributes.lang === "ur" ? "ur-container--padded" : "container--padded"
          }`}
        >
          <HamburgerIcon onClick={toggleOffcanvasMenu} />
          <AppLogo />
          <SocialFollow
            styleName="social-follow"
            socialLinks={props.socialLinks}
            color={props.publisherAttributes.lang === "ur" ? "#af33c3" : "#056ff7"}
            width={12}
            height={12}
          />
          <NavbarSearch {...props} searchIconColor="#333" styleName={styleName} />
          {props.publisherAttributes.lang === "ur" && <Couplet coupletData={props.coupletSummary} />}
          <SisterCompanies styleName="sister-companies" onLoginClick={onLoginClick} />
        </div>
      </div>

      <div id="secondary-menu" styleName={`bleeding-row ${BleedingRowMenuStyle}`}>
        <div styleName={`container menu-container ${hamburgerOpened ? "menu-container--open" : ""}`}>
          <MenuItems items={get(props, ["menu", "default"], [])} closeMenu={toggleOffcanvasMenu} />
          <NavbarSearch {...props} styleName="search-row-two" />
          <div styleName="offcanvas-static-links">
            <p styleName="offcanvas-menu__text">Follow Us</p>
            <SocialFollow
              styleName="social-follow"
              socialLinks={props.socialLinks}
              color={props.publisherAttributes.lang === "ur" ? "#af33c3" : "#056ff7"}
              width={12}
              height={12}
            />
            <SisterCompanies
              styleName="sister-companies"
              onLoginClick={onLoginClick}
              onHamburgerToggle={toggleOffcanvasMenu}
            />
          </div>
          <div
            styleName={`menu-overlay ${hamburgerOpened ? "menu-overlay--open" : ""}`}
            onClick={toggleOffcanvasMenu}
          />
        </div>
      </div>

      {/* Popup Modal's */}
      {loginPopupView()}
    </div>
  );
};

NavBarBase.propTypes = {
  socialLinks: PT.object,
  publisherAttributes: PT.object,
  coupletSummary: PT.string
};

function mapStateToProps(state) {
  return {
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"])
  };
}

export const NavBar = connect(
  mapStateToProps,
  null
)(NavBarBase);
