import React, { useState, useEffect, useRef } from "react";
import { string, object, func } from "prop-types";
import { connect } from "react-redux";
import assetify from "@quintype/framework/assetify";
import get from "lodash/get";

import { readCookie } from "../../utils/utils";
// TODO: Get sister company dynamically based on config
import { Button } from "../../basic/button";
import ProfileImage from "../../atoms/profile-image";

import NHlogo from "../../basic/images/nh.svg";
import NJlogo from "../../basic/images/nav.svg";
import QAlogo from "../../basic/images/qaumi.svg";

import {
  getUser
} from "../../utils/api";
import { setMember, setUserPlans } from "../../helper/actions";
import { setAccessTypeUser, getUserSubscriptions, loadAccessType } from "../../helper/access-type";

import "./styles.m.css";

function SisterCompaniesBase({
  className = "",
  publisherAttributes = {},
  onHamburgerToggle,
  navigateToPage,
  onLoginClick,
  member,
  setMember,
  setUserPlans,
  accessTypeV2,
  env
}) {
  const [international, setInternational] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  let [isMobile, setMobile] = useState(false);
  const menuRef = useRef(null);
  outsideClickMenu(menuRef);

  function outsideClickMenu(ref) {
    useEffect(
      () => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpenMenu(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      },
      [ref]
    );
  }

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setMobile(true);
    }
    const pathname = window.location.pathname;
    getUser()
      .then(result => {
        // result.user["phone-number"] = null;  // only for testing purpose
        setMember(result.user);

        const { user } = result;

        loadAccessType(accessTypeV2.accountKey, env, () => setATUser(user));
      })
      .catch(ex => ex.message);

    const country = readCookie("country_code");
    if (country !== "IN" && country !== "undefined") {
      setInternational(true);
      if (
        pathname.includes("/subscribe")
      ) {
        navigateToPage("/");
      }
    }

    if (pathname.includes("/subscribe")) {
      setSelectedMenu("subscribe");
    } else if (pathname.includes("/subscription")) {
      setSelectedMenu("subscription");
    }
  }, []);

  const onSubcribeClick = () => {
    onHamburgerToggle && onHamburgerToggle();
    setOpenMenu(true);
  };

  const subscribeClickmenu = () => {
    onHamburgerToggle && onHamburgerToggle();
    navigateToPage("/subscribe");
  };

  const contributeClickmenu = () => {
    onHamburgerToggle && onHamburgerToggle();
    navigateToPage("/contribute");
  };

  const setATUser = user => {
    setAccessTypeUser(user.email, user["phone-number"], user.id, user.name)
      .then(() => {
        getUserSubscriptions().then(data => setUserPlans(data.subscriptions));
      })
      .catch(ex => console.log(ex));
  };

  const profileClick = () => {
    onHamburgerToggle && onHamburgerToggle();
    navigateToPage("/profile");
  };

  const subscribeClick = path => {
    setOpenMenu(false);
    setSelectedMenu(path);
    navigateToPage(`/${path}`);
  };

  // Show the Login or Settings View depending whether user is logged in
  const loginView = () => {
    if (member) {
      return (
        <a onClick={profileClick}>
          <ProfileImage member={member} />
        </a>
      );
    }

    return (
      <Button onClick={onLoginClick} className="btn-login">
        Login
      </Button>
    );
  };

  return (
    <div className={className}>
      <div
        styleName={`sister-companies ${publisherAttributes.lang === "hi" ? "sister-company-nj" : ""} ${
          publisherAttributes.lang === "ur" ? "sister-company-qa" : ""
        } ${publisherAttributes.lang === "en" ? "sister-company-nh" : ""}`}
      >
        {publisherAttributes.lang !== "hi" ? (
          <a href="https://www.navjivanindia.com/" rel="noopener noreferrer" target="_blank">
            <img className="nj-logo" src={`${assetify(NJlogo)}`} alt="Navjivan" height="24" width="90" />
          </a>
        ) : null}
        {publisherAttributes.lang !== "ur" ? (
          <a href="https://www.qaumiawaz.com/" rel="noopener noreferrer" target="_blank">
            <img className="ur-logo" src={`${assetify(QAlogo)}`} alt="Qaumi Awaz" height="20" width="120" />
          </a>
        ) : null}
        {publisherAttributes.lang !== "en" ? (
          <a
            styleName="logo-align"
            href="https://www.nationalheraldindia.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="nh-logo" src={`${assetify(NHlogo)}`} alt="National Herald" height="14" width="140" />
          </a>
        ) : null}
        {publisherAttributes.lang !== "ur" ? (
          <Button className="epaper-btn">
            <a
              href={publisherAttributes["epaper_link"] ? publisherAttributes["epaper_link"] : "javascript:void(0)"}
              rel="noopener noreferrer"
              target="_blank"
            >
              E paper
            </a>
          </Button>
        ) : null}

        <div styleName={`subscribe-style ${international ? "international-subscribe-style" : ""}`}>
          <Button className="subscriptions-btn">
            <a onClick={onSubcribeClick}>Subscription</a>
          </Button>
          {openMenu && (
            <div
              ref={menuRef}
              styleName={`subscribe-menu ${publisherAttributes.lang === "ur" ? "subscribe-menu-ur" : ""}`}
            >
              {!international && (
                <a
                  styleName={`${selectedMenu === "subscribe" ? "selected-menu-item" : ""} image-subscribe-menu-item`}
                  onClick={() => {
                    subscribeClick("subscribe");
                  }}
                >
                  <img
                    styleName={publisherAttributes.lang === "ur" ? "nh-image-ur" : ""}
                    src="https://images.assettype.com/nationalherald/2022-12/7e1a8ece-58bc-41da-8221-47fee01e037e/National_Herald_On_Sunday.jpeg"
                  />
                  <img
                    styleName={publisherAttributes.lang === "ur" ? "nh-image-ur" : ""}
                    src="https://images.assettype.com/navjivanindia/2022-12/c58df2e1-eac2-48e3-a376-901f3fe09eb0/Sunday_Navjivan.jpeg"
                  />
                </a>
              )}
              <a
                styleName={`${
                  selectedMenu === "subscription" && publisherAttributes.lang === "en" ? "selected-menu-item" : ""
                } subscribe-menu-item`}
                href="https://www.nationalheraldindia.com/subscription"
                target="_blank"
                rel="noreferrer"
              >
                Nationalheraldindia.com
              </a>
              <a
                styleName={`${
                  selectedMenu === "subscription" && publisherAttributes.lang === "hi" ? "selected-menu-item" : ""
                } subscribe-menu-item`}
                href="https://www.navjivanindia.com/subscription"
                target="_blank"
                rel="noreferrer"
              >
                Navjivanindia.com
              </a>
              <a
                styleName={`${
                  selectedMenu === "subscription" && publisherAttributes.lang === "ur" ? "selected-menu-item" : ""
                } subscribe-menu-item`}
                href="https://www.qaumiawaz.com/subscription"
                target="_blank"
                rel="noreferrer"
              >
                Qaumiawaz.com
              </a>
            </div>
          )}
        </div>
        {isMobile && (
          <Button styleName="subscription-btn">
            <a onClick={subscribeClickmenu}>Subscribe</a>
          </Button>
        )}
        {loginView()}
        {isMobile && (
          <Button styleName={`subscription-btn contribute-btn ${member ? "contribute-btn-member" : ""}`}>
            <a onClick={contributeClickmenu}>Contribute</a>
          </Button>
        )}
      </div>
    </div>
  );
}

SisterCompaniesBase.propTypes = {
  className: string,
  publisherAttributes: object,
  member: object,
  setMember: func,
  setUserPlans: func,
  onHamburgerToggle: func,
  navigateToPage: func,
  onLoginClick: func,
  accessTypeV2: object,
  env: string
};

function mapStateToProps(state) {
  return {
    publisherAttributes: get(state, ["qt", "config", "publisher-attributes"]),
    member: state.member,
    env: get(state, ["qt", "config", "publisher-attributes", "env"], "production"),
    accessTypeV2: get(state, ["qt", "config", "publisher-attributes", "accessTypeV2"], {})
  };
}

const mapDispatchToProps = dispatch => ({
  setMember: member => dispatch(setMember(member)),
  setUserPlans: list => dispatch(setUserPlans(list)),
  navigateToPage: url => global.app.navigateToPage(dispatch, url)
});

export const SisterCompanies = connect(
  mapStateToProps,
  mapDispatchToProps
)(SisterCompaniesBase);
