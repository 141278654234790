import { generateJwtToken } from "../utils/api";

export const setAccessTypeUser = (email, mobile, id = null, name = null) => {
  return generateJwtToken(email, id, name)
    .then(token => {
      if (global.AccessType) {
        global.AccessType &&
          global.AccessType.setUser({
            emailAddress: email,
            mobileNumber: mobile,
            accesstypeJwt: token
          }).then(response => console.log("setAccessTypeUser response :>> ", response));
      }
    })
    .catch(ex => {
      console.log("setAccessTypeUser error:>> ", ex);
      Promise.reject(ex);
    });
};

export const logoutAccessTypeUser = () => global.AccessType && global.AccessType.unsetUser();

export const getPlans = contributionId => {
  return (
    global.AccessType &&
    global.AccessType.getSubscriptionPlans().then(list => {
      return list.filter(c => c.subscription_group_id === contributionId);
    })
  );
};

export const getUserSubscriptions = () => global.AccessType && global.AccessType.getSubscriptions();

export const getPaymentOptions = () => global.AccessType && global.AccessType.getPaymentOptions();

export const getAccessTypeUrl = (accountKey, env) => {
  if (env !== "staging") {
    return `https://www.accesstype.com/frontend/v2/accesstype.js?key=${accountKey}`;
  } else {
    return `https://www.accesstype.com/frontend/v2/accesstype.js?&env=sandbox&key=${accountKey}`;
  }
};

export const loadAccessType = (accountKey, env, callback) => {
  const isScriptLoaded = document.querySelector(`script[src='${getAccessTypeUrl(accountKey, env)}']`);

  if (!isScriptLoaded) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.onload = () => callback();
    script.src = getAccessTypeUrl(accountKey, env);
    document.getElementsByTagName("head")[0].appendChild(script);
  } else {
    callback();
  }
};

// For Contribution Module
export const makeRazorpayPayment = (currentPlan, paymentOptions, data, customValue) => {
  // eslint-disable-next-line camelcase
  const { id, price_cents, price_currency, title, recurring } = currentPlan;

  // eslint-disable-next-line camelcase
  const inCents = currentPlan.recurring ? price_cents : customValue * 100;

  const subscriptionParams = {
    type: "standard",
    plan: {
      id,
      price_cents: inCents,
      price_currency,
      title
    },
    payment: {
      payment_type: recurring ? "razorpay_recurring" : "razorpay",
      amount_cents: inCents,
      amount_currency: price_currency
    },
    metadata: data
  };

  return paymentOptions.razorpay.proceed(subscriptionParams);
};

// For Subscribe NewsPaper Module
export const makeRazorpayPaymentNP = (currentPlan, paymentOptions, data) => {
  // eslint-disable-next-line camelcase
  const { id, price_cents, price_currency, title, recurring } = currentPlan;

  const subscriptionParams = {
    type: "standard",
    plan: {
      id,
      price_cents: price_cents,
      price_currency,
      title
    },
    payment: {
      payment_type: recurring ? "razorpay_recurring" : "razorpay",
      amount_cents: price_cents,
      amount_currency: price_currency
    },
    metadata: data
  };

  return paymentOptions.razorpay.proceed(subscriptionParams);
};

export const cancelUserSubscription = id => global.AccessType && global.AccessType.cancelSubscription(id);

export const downloadInvoice = (subscriptionId, invoiceId) =>
  global.AccessType && global.AccessType.downloadInvoice(subscriptionId, invoiceId).then(r => r.blob());

export const setAccessTypeNonLoggedInUser = email =>
  global.AccessType &&
  global.AccessType.setUser({
    emailAddress: email,
    isLoggedIn: false,
    accesstypeJwtUrl: null
  });
